import { Component } from 'react';
import i18n from '../../config/i18n'

class BaseComponent extends Component {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
      }

      componentDidMount() {
        i18n.on('languageChanged', this.onLanguageChanged)
      }

      componentWillUnmount() {
        i18n.off('languageChanged', this.onLanguageChanged)
      }

      onLanguageChanged() {
          this.forceUpdate();
      }

      render() {
          return undefined;
      }
}

export default BaseComponent;
import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import { Container, Button } from 'reactstrap';
import i18n from '../../config/i18n';
import {withRouter} from "react-router-dom";

class NoMatch extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.navigateBack = this.navigateBack.bind(this);
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.pageNotFound'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }
    navigateBack() {
        this.props.history.goBack();
    }
    render() {
        return <Container className="text-center"><h1 style={{fontSize: 300}}>404</h1><Button onClick={() => {this.navigateBack()}} color="danger" size="lg">Go back to our magic!</Button></Container>;
    }
}

export default withRouter(connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(NoMatch));
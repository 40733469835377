import React from 'react';
import BaseComponent from "../BaseComponent";
import {Container} from "reactstrap";
import './Footer.css';
import do_logo from './digitalocean.png';

export default class Footer extends BaseComponent {
    render() {
        return <Container fluid className={'Footer'}>
            <span>Copyright &copy; {(new Date()).getFullYear()} - <a href="http://www.oliviervanbulck.com" target={'_blank'}>Olivier Van Bulck</a> | Alle rechten voorbehouden. | Sponsored by <a href="https://www.digitalocean.com" target="_BLANK"><img src={do_logo} className="DOLogo" /></a></span>
        </Container>;
    }
}

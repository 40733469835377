import React from 'react';
import { BaseComponent } from '../';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input, Label, FormFeedback
} from 'reactstrap';
import FacebookLoginButton from "./FacebookLoginButton";
import moment from "moment";
import DatePicker from 'react-datepicker';
import Recaptcha from 'react-recaptcha';
import config from '../../config/config'
import i18n from "../../config/i18n";
import {NotificationManager} from 'react-notifications';

export default class RegisterModal extends BaseComponent {
    cap(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    constructor(props) {
        super(props);
        this.state = {
            firstName: {
                value: '',
                errors: []
            },
            lastName: {
                value: '',
                errors: []
            },
            email: {
                value: '',
                errors: []
            },
            birthday: {
                value: moment(),
                errors: []
            },
            password1: {
                value: '',
                errors: []
            },
            password2: {
                value: '',
                errors: []
            },
            house: '',
            registerButtonDisabled: true,
            isRegistering: false
        };

        this.verifyRegisterCaptcha = this.verifyRegisterCaptcha.bind(this);
        this.expiredRegisterCaptcha = this.expiredRegisterCaptcha.bind(this);
        this.register = this.register.bind(this);
    }

    verifyRegisterCaptcha() {
        this.setState({registerButtonDisabled: false});
    }

    expiredRegisterCaptcha() {
        this.setState({registerButtonDisabled: true});
    }

    register() {
        this.setState({isRegistering: true});
        fetch(config.api + 'auth/register', {
            method: 'POST',
            body: JSON.stringify({
                firstName: this.state.firstName.value,
                lastName: this.state.lastName.value,
                email: this.state.email.value,
                birthday: this.state.birthday.value.format('YYYY-MM-DD'),
                password: this.state.password1.value,
                house: this.state.house
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(data => {return data.json();}).then(data => {
            //console.log(data);
            if(data.success) {
                NotificationManager.success('Je bent succesvol geregistreerd. Je kan nu inloggen!')
                this.props.toggle(undefined, true);
            }
            else {
                // Fouten oplossen

            }
            this.setState({isRegistering: false});
        });
    }

    render() {
        return <div><Modal isOpen={this.props.open} toggle={this.props.toggle}>
            <ModalHeader toggle={this.toggleRegisterModal}>Registreren</ModalHeader>
            <ModalBody>
                <FacebookLoginButton />
                <Form>
                    <FormGroup>
                        <Label for="registerFirstName">Voornaam</Label>
                        <Input type="text" name="registerFirstName" id="registerFirstName" value={this.state.firstName.value} onChange={(e) => {this.setState({firstName: {...this.state.firstName, value: e.target.value}})}}/>
                        <FormFeedback>You will not be able to see this</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="registerLastName">Familienaam</Label>
                        <Input type="text" name="registerLastName" id="registerLastName" value={this.state.lastName.value} onChange={(e) => {this.setState({lastName: {...this.state.lastName, value: e.target.value}})}}/>
                        <FormFeedback>You will not be able to see this</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="registerEmail">E-mail</Label>
                        <Input type="email" name="registerEmail" id="registerEmail" value={this.state.email.value} onChange={(e) => {this.setState({email: {...this.state.email, value: e.target.value}})}}/>
                        <FormFeedback>You will not be able to see this</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="registerBirthday">Geboortedatum</Label><br />
                        <DatePicker
                            inline
                            onChange={(date) => {this.setState({birthday: {...this.state.birthday, value: date}});}}
                            className={"form-control"}
                            style={{width: '100%'}}
                            name="registerBirthday"
                            id="registerBirthday"
                            dateFormat={"DD/MM/YYYY"}
                            placeholderText={"Selecteer een datum..."}
                            maxDate={moment()}
                            locale={this.state.flag}
                            selected={this.state.birthday.value}
                            showYearDropdown
                            showMonthDropdown
                            ref={e => this.recaptchaInstance = e}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="registerPassword">Wachtwoord</Label>
                        <Input type="password" name="registerPassword" id="registerPassword" value={this.state.password1.value} onChange={(e) => {this.setState({password1: {...this.state.password1, value: e.target.value}})}}/>
                        <FormFeedback>You will not be able to see this</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="registerPassword2">Wachtwoord (herhaal)</Label>
                        <Input type="password" name="registerPassword2" id="registerPassword2" value={this.state.password2.value} onChange={(e) => {this.setState({password2: {...this.state.password2, value: e.target.value}})}}/>
                        <FormFeedback>You will not be able to see this</FormFeedback>
                    </FormGroup>
                    <FormGroup className={"RegisterFormHouses"}>
                        <Input invalid={config.houses.indexOf(this.state.house) === -1} hidden />
                        {
                            config.houses.map((house) => (
                                <img key={house} src={"/img/houses/"+house+".png"} alt={this.cap(house)} title={this.cap(house)} className={this.state.house === house ? 'selected' : ''} onClick={() => {this.setState({house: house})}} />
                            ))
                        }
                        <FormFeedback>Gelieve een afdeling te kiezen</FormFeedback>
                    </FormGroup>
                    <Recaptcha
                        sitekey={"6LeckU0UAAAAAJ9Qu-w32F8ZAMVJsCCEJrEnYCSF"}
                        render="explicit"
                        verifyCallback={this.verifyRegisterCaptcha}
                        expiredCallback={this.expiredRegisterCaptcha}
                    />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={this.state.registerButtonDisabled || this.state.password1.value !== this.state.password2.value || this.state.firstName.value.length < 2 || this.state.lastName.value.length < 2 || !/\S+@\S+/.test(this.state.email.value) || this.state.password1.value.length < 6} onClick={this.register}>Registreer</Button>{' '}
                <Button color="secondary" onClick={this.props.toggle}>Annuleren</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.isRegistering}>
            <ModalBody className={'text-center'}>
                <h4>{i18n.t('registering')}</h4><br />
                <i className="fas fa-spinner fa-spin fa-3x"/>
            </ModalBody>
        </Modal></div>;
    }
}
import React from "react";
import { BaseComponent, NavigationBar, Header, Footer } from "./common";
import "./App.css";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  Home,
  NoMatch,
  AdminsMods,
  Webshop,
  Gallery,
  Events,
  Contact,
  AdminsModsDetail,
  Support,
  Cookies,
  AuthRedirect,
  Profile,
  MyEvents,
  AddEvent,
  Halloween,
} from "./screens";
import config from "./config/config";
import { loadUserInfo } from "./redux/actions";
import { NotificationContainer } from "react-notifications";
import { Alert } from "reactstrap";
import "./react-toggle.css";
import Christmas from "./screens/Christmas";

class App extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      warning: false,
    };
    this.removeWarning = this.removeWarning.bind(this);
  }

  removeWarning() {
    localStorage.setItem("cookie-warning", "ok");
    this.setState({ warning: false });
  }

  componentDidMount() {
    let token = localStorage.getItem("token");
    if (token !== null && token !== undefined && token !== "") {
      fetch(config.api + "users/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((reske) => {
          if (reske.ok) return reske.json();
        })
        .then((reske) => {
          if (reske) this.props.loadUserInfo(reske);
          else console.log("Fout bij data invoeren...");
        });
    }
    let warningShown = localStorage.getItem("cookie-warning");
    if (warningShown !== "ok") this.setState({ warning: true });
  }

  render() {
    return (
      <Router>
        <div>
          <Alert
            color="warning"
            className={"cookie-warning"}
            isOpen={this.state.warning}
            toggle={this.removeWarning}
          >
            <strong>Belgian Potterheads</strong> gebruikt{" "}
            <strong>cookies</strong> om je ervaring magischer te maken. Door
            verder te gaan stem je in met het gebruik hiervan.{" "}
            <Link to={"/cookies"} className={"alert-link"}>
              Meer info.
            </Link>
          </Alert>
          {/*<Alert color="header-purple" className={'cookie-warning text-center'}>
                        Steun <strong>Belgian Potterheads</strong> met je dagelijkse inkopen. Koop je spullen bij één van de online webshops via onze <a href={"https://trooper.be/belgianpotterheads"} target={"_blank"} className={'alert-link'}>Trooperpagina</a> en help ons!
                    </Alert>*/}
          <NavigationBar />
          <Header title={this.props.title} />
          <NotificationContainer />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/team" exact component={AdminsMods} />
            <Route path="/team/:name" component={AdminsModsDetail} />
            <Route path="/events" component={Events} />
            <Route path="/webshop" component={Webshop} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/support" component={Support} />
            <Route path="/contact" component={Contact} />
            <Route path="/cookies" component={Cookies} />
            <Route path="/auth-redirect/:token" component={AuthRedirect} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/profile/events" component={MyEvents} />
            <Route path="/admin/events/add" component={AddEvent} />
            <Route path="/halloween-2023" component={Halloween} />
            <Route path="/christmas-2023" component={Christmas} />
            <Route component={NoMatch} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default connect(
  (state) => {
    return { title: state.title };
  },
  (dispatch) => {
    return { loadUserInfo: (token) => dispatch(loadUserInfo(token)) };
  }
)(App);

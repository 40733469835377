import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardText, CardTitle, Col, Container, Row} from 'reactstrap';
import i18n from '../../config/i18n';
import {Link} from "react-router-dom";

class Support extends BaseComponent {
    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.support'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }
    render() {
        return <Container>
            <Card className="well-info mb-5">
                <CardBody>
                    <CardTitle>Waarom ons steunen?</CardTitle>
                    <hr />
                    <CardText>
                        Belgian Potterheads bestaat uit een team van admins en moderators die er alles aan doen zoveel mogelijk voor hun leden te organiseren en uit te werken. Dit doen ze allemaal in hun vrije tijd, naast een drukke agenda waarin school, werk en tal van verantwoordelijkheden ook aanwezig zijn.<br /><br />
                        Om dit alles te kunnen doen, hebben we uiteraard ook geld nodig. We proberen onze beperkte inkomsten steeds zo goed mogelijk te beheren, maar deze blijven beperkt en uitzonderlijke kosten kunnen steeds voorvallen. Daarnaast zijn er verschillende kosten om onze werking te ondersteunen. Een nieuwe stand voor conventies, afhuren van locaties voor evenementen, het online houden van onze website, prijzen voor wedstrijden, voorschotten en betalingen die op voorhand moeten gebeuren, ...<br/><br />
                        Om alles toch in goede banen te leiden, hebben we dus geld nodig. En daar kan jij ons bij helpen! Hoe? Dat vind je hier:
                    </CardText>
                </CardBody>
            </Card>
            <Row>
                <Col md={6}>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <CardTitle>Webshop</CardTitle>
                            <hr />
                            <CardText>
                                In onze webshop vind je merchandise van Belgian Potterheads. Van t-shirts en hoodies tot lanyards en mokken! Voor elk wat wils!<br /><br />
                                <Button tag={Link} to={"/webshop"} color={"header-purple"} outline size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fas fa-shopping-cart"} /> Bezoek onze webshop</Button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <CardTitle>Trooper</CardTitle>
                            <hr />
                            <CardText>
                                Wil je geen geld uitgeven? Je kan ons ook steunen door te shoppen, zonder een cent extra te betalen! Shop in je favoriete webwinkels via Trooper en een deel van je aankoopprijs gaat naar ons als steun!<br /><br />
                                <Button tag={"a"} href={"https://trooper.be/belgianpotterheads"} target={"_blank"} color={"header-purple"} outline size={"lg"} className={"w-100 mb-1 text-left"}>Steun ons op <img alt={"Trooper"} style={{height: 30}} src={"/img/logo_trooper_small.gif"} /></Button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <CardTitle>Vrije bijdrage</CardTitle>
                            <hr />
                            <CardText>
                                Uiteraard stellen we het ook zeker op prijs als je ons een bijdrage wil geven. Je kan dit doen <strong>via onze stand</strong> op de meeste conventies (bekijk zeker onze evenementen zodat je weet waar we aanwezig zullen zijn) of via ons rekeningnummer <strong>BE66 9731 2356 9243</strong>.<br /><br />
                                <Button tag={Link} to={"/events"} color={"header-purple"} outline size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fas fa-calendar-alt"} /> Evenementen</Button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <CardTitle>Materiaal en locaties</CardTitle>
                            <hr />
                            <CardText>
                                Heb je het perfecte materiaal voor één van onze evenementen of onze stand? Heb je nuttige dingen liggen die wij nog kunnen hergebruiken? Of weet je de perfecte locatie voor ons volgende evenement dat we met korting of zelfs gratis zouden mogen huren? Contacteer ons dan snel en we zullen je eeuwig dankbaar zijn!<br /><br />
                                <Button tag={Link} to={"/contact"} color={"header-purple"} outline size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fas fa-comment"} /> Contacteer ons</Button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Support);
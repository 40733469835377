import React from 'react';
import {BaseComponent} from "../../common";
import i18n from "../../config/i18n";
import {changeTitle} from "../../redux/actions";
import {connect} from "react-redux";
import {Container} from "reactstrap";

class Profile extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }

    onLanguageChanged(props) {
        if(props && props.user) {
            this.props.changeTitle(props.user.firstName + ' ' + props.user.lastName);
        }
        else if(this.props.user) {
            this.props.changeTitle(this.props.user.firstName + ' ' + this.props.user.lastName);
        }
        else {
            this.props.changeTitle(i18n.t('title.profile'));
        }
    }

    componentWillReceiveProps(props) {
        this.onLanguageChanged(props);
    }

    componentWillMount() {
        this.onLanguageChanged();
    }

    render() {
        return <Container>Profile</Container>;
    }
}

export default connect((state) => {
    return {user: state.user}
}, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Profile);
import React, { Component } from 'react';

export default class AuthRedirect extends Component {
    componentDidMount() {
        console.log(this.props);
        window.opener.postMessage({type: 'access-token', value: this.props.match.params.token}, "*");
        window.close();
    }

    render() {
        return <div></div>;
    }
}
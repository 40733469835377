import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

i18next
  .use(LngDetector)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    fallbackLng: 'en',
      lng: 'nl',
    //lng: 'nl', // 'en' | 'es'
    // Using simple hardcoded resources for simple example
    resources: {
      en: require('./locales/en.json'),
      nl: require('./locales/nl.json'),
      fr: require('./locales/fr.json'),
    },
  });

export default i18next;
import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import { Container } from 'reactstrap';
import i18n from '../../config/i18n';

class Gallery extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.gallery'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }
    render() {
        return <Container>Galerij</Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Gallery);
import { BaseComponent } from "../../common";
import { Button, Container } from "reactstrap";
import i18n from "../../config/i18n";
import { connect } from "react-redux";
import { changeTitle } from "../../redux/actions";
import halloween from "./halloween.json";

class Halloween extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      started: false,
      paragraphs: [],
    };
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.onStart = this.onStart.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onSelectOption = this.onSelectOption.bind(this);
    this.reset = this.reset.bind(this);
  }

  onStart() {
    this.setState({ started: true });
    this.goNext();
  }

  reset() {
    this.setState(
      {
        paragraphs: [],
        ended: false,
        won: false,
        currentElement: undefined,
        started: false,
      },
      this.onStart
    );
  }

  goNext() {
    const nextPart =
      this.state.currentElement !== undefined
        ? this.state.currentElement + 1
        : 0;
    this.setState((prevState) => ({
      currentElement: nextPart,
      paragraphs: [...prevState.paragraphs, ...halloween[nextPart].paragraphs],
    }));
    if (!halloween[nextPart].options) {
      this.setState({ ended: true, won: true });
    }
  }

  onSelectOption(optionIndex) {
    const option = halloween[this.state.currentElement].options[optionIndex];

    this.setState((prevState) => ({
      paragraphs: [...prevState.paragraphs, ...option.paragraphs],
    }));

    if (!option.correct) {
      this.setState((prevState) => ({ ended: true, won: false }));
    } else {
      this.goNext();
    }
  }

  onLanguageChanged() {
    this.props.changeTitle(i18n.t("title.halloween2023"));
  }
  componentWillMount() {
    this.onLanguageChanged();
  }
  render() {
    return (
      <Container>
        <h1>Halloween Mini Escape Room</h1>
        {!this.state.started && (
          <>
            <p>
              Welkom, beste Belgian Potterhead! Ben je klaar om te gaan
              griezelen in een mini Halloween Escape Room? Druk snel op de knop
              om te starten!
            </p>
            <p className="text-center">
              <Button onClick={this.onStart}>Start je avontuur!</Button>
            </p>
          </>
        )}
        {this.state.started && (
          <>
            {this.state.paragraphs.map((p, i) => (
              <p key={i}>{p}</p>
            ))}
            {!this.state.ended &&
              halloween[this.state.currentElement].options?.map((option, i) => (
                <div className="text-center mb-3" key={i}>
                  <Button
                    onClick={() => this.onSelectOption(i)}
                    className="text-break"
                  >
                    {option.optionText}
                  </Button>
                </div>
              ))}
          </>
        )}
        {this.state.ended && (
          <>
            {this.state.won && (
              <div className="text-center">
                <h1>Proficiat! Je hebt succesvol het feestmaal bereikt!</h1>
                <p>
                  Namens iedereen bij Belgian Potterheads wensen we onze leden
                  een fijne en griezelige Halloweenavond!
                </p>
                <Button onClick={this.reset}>Speel nog een keer!</Button>
              </div>
            )}
            {!this.state.won && (
              <div className="text-center">
                <h1>Helaas... Je hebt het feestmaal niet bereikt...</h1>
                <Button onClick={this.reset}>Probeer opnieuw</Button>
              </div>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default connect(null, (dispatch) => {
  return { changeTitle: (title) => dispatch(changeTitle(title)) };
})(Halloween);

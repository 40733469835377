import React from 'react';
import { BaseComponent } from '../';
import {
    DropdownItem,
    DropdownMenu,
    //Button,
    Nav,
    Navbar,
    NavLink,
    NavItem,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';
import {NavLink as RRNavLink, withRouter} from 'react-router-dom';
import i18n from '../../config/i18n';
import 'react-datepicker/dist/react-datepicker.min.css';
import './NavigationBar.css';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import {connect} from "react-redux";
import {logout} from "../../redux/actions";
import {NotificationManager} from 'react-notifications';
import ReactTooltip from 'react-tooltip';
import md5 from 'md5';

const flags = require('../../config/config.json').flags;
class NavigationBar extends BaseComponent {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleLoginModal = this.toggleLoginModal.bind(this);
        this.toggleRegisterModal = this.toggleRegisterModal.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

        this.state = {
            flag: 'nl',
            isOpen: false,
            loginModalOpen: false,
            registerModalOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleLoginModal() {
        this.setState({
            loginModalOpen: !this.state.loginModalOpen,
        });
    }

    toggleRegisterModal(e, registered = false) {
        if(registered) {
            this.toggleLoginModal();
        }
        this.setState({
            registerModalOpen: !this.state.registerModalOpen
        });
    }

    changeLanguage(lang) {
        i18n.changeLanguage(lang);
        this.setState({flag: flags[lang]});
    }

    componentDidMount() {
        console.log(i18n.language);
        this.changeLanguage(i18n.language.substr(0,2));
    }

    handleLogout() {
        this.props.logout();
        this.props.history.replace('/');
        NotificationManager.success('Je bent succesvol uitgelogd!');
    }

    render() {
        let location = this.props.history.location.pathname.substr(1);
        return <div>
            <Navbar dark expand="xl" className="bg-dark" sticky={'true'}>
                <NavbarBrand to="/" tag={RRNavLink}>
                    <img src="/img/logo.png" alt="Belgian Potterheads" className="App-logo"/>
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle}/>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav navbar>
                        <NavItem>
                            <NavLink tag={RRNavLink} to="/" exact>{i18n.t('nav.start')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RRNavLink} to="/team">{i18n.t('nav.adminsMods')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RRNavLink} to="/events">{i18n.t('nav.events')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RRNavLink} to="/webshop">{i18n.t('nav.webshop')}</NavLink>
                        </NavItem>
                        {/*<NavItem>
                            <NavLink tag={RRNavLink} to="/gallery">{i18n.t('nav.gallery')}</NavLink>
                        </NavItem>*/}
                        <NavItem>
                            <NavLink tag={RRNavLink} to="/support">{i18n.t('nav.help')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RRNavLink} to="/contact">{i18n.t('nav.contact')}</NavLink>
                        </NavItem>
                    </Nav>
                    <Nav navbar className="ml-auto App-header-button-group">
                        {this.props.user && <NavItem style={{position: 'relative', top: -2}}>
                            {this.props.user && this.props.user.admin &&
                            <div style={{position: 'relative', top: 8, display: 'inline-block'}}>
                                <ReactTooltip place="bottom" type="dark" effect="solid" id={'rt-admin'}>
                                    <span>Je bent een admin</span>
                                </ReactTooltip>
                                <span className="fa-layers fa-fw fa-2x" data-tip data-for="rt-admin">
                                <i className={"fas fa-circle text-"+this.props.user.house} />
                                <i className="fa-inverse fas fa-star" data-fa-transform="shrink-8"/>
                            </span>
                            </div>}
                            {this.props.user && this.props.user.mod &&
                            <div style={{position: 'relative', top: 8, display: 'inline-block'}}>
                                <ReactTooltip place="bottom" type="dark" effect="solid" id={'rt-mod'}>
                                    <span>Je bent een moderator</span>
                                </ReactTooltip>
                                <span className="fa-layers fa-fw fa-2x" data-tip data-for="rt-mod">
                                <i className={"fas fa-circle text-"+this.props.user.house} />
                                <i className="fa-inverse fas fa-check" data-fa-transform="shrink-8"/>
                              </span>
                            </div>}
                            <img src={'https://www.gravatar.com/avatar/'+md5(this.props.user.email)+'?s=36&d=retro'} className={'img-responsive navbar-profile-pic rounded-circle border border-' + this.props.user.house} alt={this.props.user.firstName + ' ' + this.props.user.lastName} /></NavItem>}
                        {this.props.user &&
                        <UncontrolledDropdown nav inNavbar className={location.startsWith('profile') ? 'active' : ''} style={{top: 4}}>
                            <DropdownToggle nav caret>
                                {this.props.user.firstName} {this.props.user.lastName}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag={RRNavLink} to={'/profile'} exact>
                                    Mijn profiel
                                </DropdownItem>
                                <DropdownItem tag={RRNavLink} to={'/profile/events'}>
                                    Mijn evenementen
                                </DropdownItem>
                                <DropdownItem divider />
                                {this.props.user.admin && <DropdownItem header>Admin</DropdownItem>}
                                {this.props.user.mod && <DropdownItem header>Moderator</DropdownItem>}
                                {this.props.user.admin && <DropdownItem tag={RRNavLink} to={'/admin/events'}>Evenementen</DropdownItem>}
                                {this.props.user.admin && <DropdownItem tag={RRNavLink} to={'/admin/photos'}>Galerij</DropdownItem>}
                                {this.props.user.admin && <DropdownItem tag={RRNavLink} to={'/admin/projects'}>Projecten</DropdownItem>}
                                {(this.props.user.admin || this.props.user.mod) && <DropdownItem tag={RRNavLink} to={'/admin/ideas'}>Ideeënbox</DropdownItem>}
                                {(this.props.user.admin || this.props.user.mod) && <DropdownItem divider />}
                                <DropdownItem onClick={this.handleLogout}>
                                    Uitloggen
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>}
                        {/*{!this.props.user &&
                            <Button outline color="success" onClick={this.toggleLoginModal}><i
                                className="fas fa-user"/> {i18n.t('login')}</Button>
                        }
                        {!this.props.user &&
                        <Button outline color="info" onClick={this.toggleRegisterModal}><i
                            className="fas fa-user-plus"/> {i18n.t('register')}</Button>
                        }
                        <UncontrolledDropdown nav inNavbar style={{top: 4}}>
                            <DropdownToggle caret nav>
                                <span className={"flag-icon flag-icon-" + this.state.flag}/>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right">
                                <DropdownItem header>Taalkeuze</DropdownItem>
                                <DropdownItem onClick={() => {
                                    this.changeLanguage('nl')
                                }}><span className="flag-icon flag-icon-nl"/> Nederlands</DropdownItem>
                                <DropdownItem onClick={() => {
                                    this.changeLanguage('fr')
                                }}><span className="flag-icon flag-icon-fr"/> Français</DropdownItem>
                                <DropdownItem onClick={() => {
                                    this.changeLanguage('en')
                                }}><span className="flag-icon flag-icon-gb"/> English</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>*/}
                    </Nav>
                </Collapse>
            </Navbar>
            <LoginModal toggle={this.toggleLoginModal} open={this.state.loginModalOpen} />
            <RegisterModal toggle={this.toggleRegisterModal} open={this.state.registerModalOpen} />
        </div>;
    }
}

export default withRouter(connect((state) => {
    return {user: state.user}
}, (dispatch) => {
    return {logout: () => {dispatch(logout())}}
}, null, {pure:false})(NavigationBar));
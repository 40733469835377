import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import {Card, CardBody, CardText, Container} from 'reactstrap';
import i18n from '../../config/i18n';

class Cookies extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.cookies'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }
    render() {
        return <Container>
            <Card className="well-info mb-5">
                <CardBody>
                    <CardText>
                        <strong>Belgian Potterheads</strong> gebruikt cookies om je ervaring magischer te maken. Wat zijn cookies en waarom worden ze gebruikt? Meer informatie vind je hier!
                    </CardText>
                </CardBody>
            </Card>
            <h3>Wat zijn cookies?</h3>
            <p>
                Cookies zijn kleine stukken informatie die worden opgeslagen op de computer of ander toestel waarmee een gebruiker de website bezoekt. Deze bestanden worden gebruikt om de website optimaal te laten functioneren. Zo kunnen we bijhouden in welke taal we de website aan je moeten tonen, wie je bent om je op die manier ingelogd te laten en nog enkele dingen die we nodig hebben om je de ervaring te geven die je verwacht. Er zal <strong>nooit</strong> persoonlijke informatie worden opgeslagen zoals wachtwoorden, naam, e-mailadres, ...
            </p>
            <p>
                Er zijn verschillende soorten cookies die verschillen in geldigheidstermijn of het domein waarover de cookie geldig is. Zo zullen bepaalde cookies van Belgian Potterheads geldig zijn over andere delen van onze applicaties, terwijl sommige cookies enkel geldig zijn op bijvoorbeeld deze website.
            </p>
            <p>
                Meer informatie over cookies vind je op <a href={"http://www.allaboutcookies.org"} target={'_blank'}>www.allaboutcookies.org</a>.
            </p>
            <h3>Waarvoor gebruiken wij cookies?</h3>
            <p>
                Belgian Potterheads gebruikt cookies om:
            </p>
            <ul>
                <li>Het gebruik en de functionaliteiten op onze website te verbeteren</li>
                <li>Je ingelogd te houden over ons hele platform</li>
                <li>Gepersonaliseerde inhoud te tonen aan gebruikers</li>
                <li>Bijhouden van belangrijke informatie zoals taal en voorkeuren</li>
            </ul>
        </Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Cookies);
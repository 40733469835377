import * as t from './action-types';

const initialState = {
    title: '',
    language: (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage,
    user: null
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case t.CHANGE_TITLE:
            document.title = 'Belgian Potterheads | ' + action.payload;
            return Object.assign({}, state, {title: action.payload});
        case t.LOAD_USER_INFO:
            return Object.assign({}, state, {user: action.payload});
        case t.LOGOUT:
            localStorage.removeItem('token');
            return Object.assign({}, state, {user: null});
        default:
            return state;
    }
};
export default rootReducer;
import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import {Container, Button, Row, Col, Form, FormGroup, Label, Input, ButtonGroup} from 'reactstrap';
import i18n from '../../config/i18n';

class Contact extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.sendmail = this.sendmail.bind(this);
        this.state = {
            form: {
                name: '',
                email: '',
                message: ''
            }
        }
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.contact'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }
    sendmail(e) {
        e.preventDefault();
        fetch('https://www.oliviervanbulck.com/bpmail.php', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({name: this.state.form.name, email: this.state.form.email, message: this.state.form.message})
        })
            .then((response) => {
                if(!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then((data) => {
                alert("Je bericht werd succesvol verzonden!");
                this.setState({form: {
                    name: '', email: '', message: ''
                    }})
            })
            .catch((e) => {
                alert("Er ging iets mis tijdens het verzenden van het formulier...");
            });
    }
    render() {
        return <Container>
            <Row>
                <Col md={8}>
                    <h1>{i18n.t('pages.contact.formTitle')}</h1>
                    <Form onSubmit={this.sendmail}>
                        <FormGroup>
                            <Label for="contactName">{i18n.t('pages.contact.form.name')}</Label>
                            <Input type="text" name="contactName" id="contactName" value={this.state.form.name} onChange={(e) => {this.setState({form: {...this.state.form, name: e.target.value}})}}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="contactEmail">{i18n.t('pages.contact.form.email')}</Label>
                            <Input type="email" name="contactEmail" id="contactEmail" value={this.state.form.email} onChange={(e) => {this.setState({form: {...this.state.form, email: e.target.value}})}}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="contactMessage">{i18n.t('pages.contact.form.message')}</Label>
                            <Input type="textarea" rows={4} name="contactMessage" id="contactMessage" value={this.state.form.message} onChange={(e) => {this.setState({form: {...this.state.form, message: e.target.value}})}}/>
                        </FormGroup>
                        <Button>{i18n.t('pages.contact.send')}</Button>
                    </Form>
                </Col>
                <Col md={4}>
                    <h1>{i18n.t('pages.contact.socialTitle')}</h1>
                    <ButtonGroup className={"w-100"}>
                        <Button tag={"a"} href={"https://facebook.com/BelgianPotterheads"} target={"_BLANK"} color={"ravenclaw"} size={"lg"} className={"w-50 mb-1 text-left"}><i className={"fab fa-facebook"} /> {i18n.t('pages.contact.facebook')}</Button>
                        <Button tag={"a"} href={"https://www.messenger.com/t/BelgianPotterheads"} target={"_BLANK"} color={"ravenclaw"} size={"lg"} className={"w-50 mb-1 text-left"}><i className={"fab fa-facebook-messenger"} /> {i18n.t('pages.contact.messenger')}</Button>
                    </ButtonGroup>
                    <ButtonGroup className={"w-100"}>
                        <Button tag={"a"} href={"https://twitter.com/BePotterheads"} target={"_BLANK"} color={"info"} size={"lg"} className={"w-50 mb-1 text-left"}><i className={"fab fa-twitter"} /> {i18n.t('pages.contact.twitter')}</Button>
                        <Button tag={"a"} href={"https://twitter.com/intent/tweet?text=@BePotterheads%20"} target={"_BLANK"} color={"info"} size={"lg"} className={"w-50 mb-1 text-left"}><i className={"fas fa-comments"} /> {i18n.t('pages.contact.tweet')}</Button>
                    </ButtonGroup>
                    <a href={"mailto:belgianpotterheads@gmail.com"} target={"_BLANK"}><Button color={"success"} size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fas fa-at"} /> {i18n.t('pages.contact.email')}</Button></a>
                </Col>
            </Row>
        </Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Contact);
/*
<a href={"https://www.facebook.com/messages/t/BelgianPotterheads"} target={"_BLANK"}><Button color={"primary"}><i className={"fab fa-facebook-messenger"} /> Facebook</Button></a><a href={"https://twitter.com/BePotterheads"} target={"_BLANK"}><Button color={"info"}><i className={"fab fa-twitter"} /> Twitter</Button></a>
 */
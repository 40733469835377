import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import './Header.css';
import bg from './bg.png';
import {connect} from "react-redux";
import config from '../../config/config'

const Header = (props) => {
    let color, classname, logo;
    if(config.houses.indexOf(props.color) !== -1) {
        classname = props.color;
        logo = true;
    }
    else {
        color = props.color || '#785589';
    }
    return <header style={{backgroundColor: color, backgroundImage: "url('" + bg + "')"}} className={"Header bg-" + classname}>
        <Container>
            <Row>
                <Col>
                    <h1>{props.title}</h1>
                </Col>
                {logo && <div className={'house'}>
                    <img src={'/img/houses/'+classname+'.png'} alt={classname} style={{width: 200}} />
                </div>}
            </Row>
        </Container>
    </header>;
};

export default connect((state) => {
    return {color: state.user && state.user.house ? state.user.house : undefined}
})(Header);
import React from 'react';
import { BaseComponent } from '../';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import FacebookLoginButton from "./FacebookLoginButton";
import i18n from '../../config/i18n';
import config from '../../config/config';
import {connect} from "react-redux";
import {loadUserInfo} from "../../redux/actions";
import {NotificationManager} from 'react-notifications';

class LoginModal extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isLoggingIn: false
        };
        this.login = this.login.bind(this);
    }

    login(e) {
        e.preventDefault();
        this.setState({isLoggingIn: true});
        fetch(config.api + 'auth/login', {
            method: 'POST',
            body: JSON.stringify({
                username: this.state.email,
                password: this.state.password,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(data => {return data.json();}).then(data => {
            console.log(data);
            if(data.success) {
                localStorage.setItem("token", data.token);
                //this.props.loadUserInfo(data.token);
                fetch(config.api + 'users/me', {
                    headers: {
                        'Authorization': 'Bearer ' + data.token
                    }
                }).then((reske => {
                    if(reske.ok)
                        return reske.json();
                    else
                        NotificationManager.error('Er is een fout opgetreden tijdens het inloggen... Probeer het later nog een keer.')
                })).then((reske) => {
                    if(reske) {
                        this.props.loadUserInfo(reske);
                        NotificationManager.success('Welkom, '+reske.firstName + ' ' + reske.lastName+'! Je bent succesvol ingelogd.');
                        this.props.toggle();
                    }
                    else
                        NotificationManager.error('Er is een fout opgetreden tijdens het inloggen... Probeer het later nog een keer.')
                });
            }
            else {
                // Fouten oplossen
                //this.setState({showWarning: true});
                NotificationManager.error('De combinatie van gebruikersnaam en wachtwoord is niet correct.');
            }
            this.setState({isLoggingIn: false});
        });
    }

    render() {
        return <div><Modal isOpen={this.props.open} toggle={this.props.toggle}>
            <ModalHeader toggle={this.props.toggle}>Inloggen</ModalHeader>
            <ModalBody>
                <FacebookLoginButton />
                <Form>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className={"fas fa-user"} /></InputGroupText></InputGroupAddon>
                            <Input type="email" placeholder={"E-mail"} name="email" id="loginEmail" value={this.state.email} onChange={(e) => {this.setState({email: e.target.value})}}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className={"fas fa-lock"} /></InputGroupText></InputGroupAddon>
                            <Input type="password" placeholder={"Wachtwoord"} name="loginPassword" id="loginPassword" value={this.state.password} onChange={(e) => {this.setState({password: e.target.value})}}/>
                        </InputGroup>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.login}>Inloggen</Button>{' '}
                <Button color="secondary" onClick={this.props.toggle}>Annuleren</Button>
            </ModalFooter>
        </Modal><Modal isOpen={this.state.isLoggingIn}>
            <ModalBody className={'text-center'}>
                <h4>{i18n.t('loggingIn')}</h4><br />
                <i className="fas fa-spinner fa-spin fa-3x"/>
            </ModalBody>
        </Modal></div>;
    }
}

export default connect(null, (dispatch) => {return {loadUserInfo: token => dispatch(loadUserInfo(token))}})(LoginModal);
//export default LoginModal;
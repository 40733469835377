import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardText, CardTitle, Col, Container, Row} from 'reactstrap';
import i18n from '../../config/i18n'
import {Link} from "react-router-dom";
//import Calendar from "../../common/Calendar";

class Home extends BaseComponent {
    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.start'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }
    render() {
        return <Container>
            <h1>Welkom bij Belgian Potterheads!</h1>
            <Row>
                <Col lg={4}>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <CardTitle>Wie zijn wij?</CardTitle>
                            <hr />
                            <CardText>
                                <strong>Belgian Potterheads</strong> is een feitelijke vereniging die fans van <strong>Harry Potter</strong> wil samenbrengen op een unieke en levendige manier. Een groot deel van onze magie wordt verspreid via <strong>Facebook</strong>, waar onze twee groepen open staan voor alle tovenaars en heksen die een kijkje willen komen nemen in de magische wereld van Harry Potter.
                                <br /><br /><Button tag={Link} to={"/adminsmods"} color={"header-purple"} outline size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fas fa-users"} /> Onze admins & mods</Button>
                                <br /><Button tag={Link} to={"/support"} color={"header-purple"} outline size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fas fa-life-ring"} /> Steun ons</Button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <CardTitle>Wat doen wij?</CardTitle>
                            <hr />
                            <CardText>
                                Naast onze groepen op Facebook, waarin we de magische wereld delen via hedendaagse technologie, organiseren we ook tal van <strong>evenementen</strong>. Kijk zeker eens op onze evenementen-pagina wat we de komende tijd in petto hebben, het is zeker en vast de moeite. Daarnaast proberen we ook toffe <strong>wedstrijden</strong> te bedenken en steeds meer en meer delen van de magie op verschillende manieren naar onze leden toe te brengen.
                                <br /><br /><Button tag={Link} to={"/events"} color={"header-purple"} size={"lg"} outline className={"w-100 mb-1 text-left"}><i className={"far fa-calendar-alt"} /> Evenementen</Button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <CardTitle>Waar vind je ons?</CardTitle>
                            <hr />
                            <CardText tag={'span'}>
                                <h3>Facebook</h3>
                                <Button tag={"a"} href={"https://www.facebook.com/groups/belgianpotterheadsunite/"} target={"_BLANK"} color={"ravenclaw"} size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fab fa-facebook"} /> Hoofdgroep</Button>
                                <br /><Button tag={"a"} href={"https://www.facebook.com/groups/BelgianPotterheadsPersonal/"} target={"_BLANK"} color={"ravenclaw"} size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fab fa-facebook"} /> Persoonlijke groep</Button>
                                <br /><Button tag={"a"} href={"https://facebook.com/BelgianPotterheads"} target={"_BLANK"} color={"ravenclaw"} size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fab fa-facebook"} /> Pagina</Button>
                                <br /><br />
                                <h3>Andere</h3>
                                <Button tag={"a"} href={"https://twitter.com/BePotterheads"} target={"_BLANK"} color={"info"} outline size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fab fa-twitter"} /> {i18n.t('pages.contact.twitter')}</Button>
                                <Button tag={"a"} href={"https://www.youtube.com/channel/UCmtONguBK7GJhm2KovZY-Ng"} target={"_BLANK"} color={"danger"} outline size={"lg"} className={"w-100 mb-1 text-left"}><i className={"fab fa-youtube"} /> {i18n.t('pages.contact.youtube')}</Button>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/*<h2>Kalender</h2>
            <Calendar />*/}
        </Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Home);
import React from 'react';
import {BaseComponent} from "../index";
import {connect} from "react-redux";
import {NoMatch} from "../../screens";

class AdminComponent extends BaseComponent {
    componentWillReceiveProps(props) {
        if(props.onTitleChange && props.user && props.user.admin) props.onTitleChange();
    }

    render() {
        return this.props.user && this.props.user.admin ? this.props.children : <NoMatch />;
    }
}

export default connect((state) => {return {user: state.user, title: state.title}})(AdminComponent);
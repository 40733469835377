import './Webshop.css';
import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import { Container } from 'reactstrap';
import i18n from '../../config/i18n';

class Webshop extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.webshop'));
    }
    componentWillMount() {
        this.onLanguageChanged();
        const script = document.createElement("script");
        const scriptText = document.createTextNode("var spread_shop_config = {shopName: 'BelgianPotterheads',locale: 'en_BE',prefix: '//shop.spreadshirt.be',baseId: 'myShop'};");
        script.appendChild(scriptText);
        script.async = true;

        const scriptSrc = document.createElement("script");
        scriptSrc.src = "//shop.spreadshirt.be/shopfiles/shopclient/shopclient.nocache.js";
        scriptSrc.async = true;

        document.body.appendChild(script);
        document.body.appendChild(scriptSrc);
    }
    render() {
        return <Container id={"Webshop"}>
            <div id="myShop">
                <div className="text-center" style={{marginTop: 40}}>
                    <i className={"fas fa-spin fa-circle-notch fa-5x"} />
                </div>
            </div>
        </Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Webshop);
import './AdminsMods.css';
import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import { Container, Card, CardText, CardBody, CardTitle } from 'reactstrap';
import i18n from '../../config/i18n';
import admins from '../../config/admins.json';
import mods from '../../config/mods.json';
import organizers from '../../config/organizers.json';

class AdminsMods extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
        this.navigate = this.navigate.bind(this);
    }

    navigate(link) {
        this.props.history.push(link);
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.adminsMods'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }
    render() {
        return <Container>
            <Card className="well-info mb-5">
                <CardBody>
                    <CardTitle>{i18n.t('pages.team.infoTitle')}</CardTitle>
                    <hr />
                    <CardText>{i18n.t('pages.team.infoMessage')}</CardText>
                </CardBody>
            </Card>
            <div className={"text-center"}>
                <h1>{i18n.t('pages.team.admins')}</h1>
                {admins.map((element) => {return <div key={element.slug} className={"AdminsMods-head"}><div className={"rounded-circle " + element.slug + " border-" + element.house + " " + element.house} style={{backgroundImage: "url('/img/team/"+element.slug+".png')"}} /*onClick={() => {this.navigate("/adminsmods/" + element.slug);}}*/><div><span>{element.first_name + ' ' + element.last_name}</span></div></div></div>;})}
                <h1>{i18n.t('pages.team.organizers')}</h1>
                {organizers.map((element) => {return <div key={element.slug} className={"AdminsMods-head"}><div className={"rounded-circle " + element.slug + " border-" + element.house + " " + element.house} style={{backgroundImage: "url('/img/team/"+element.slug+".png')"}} /*onClick={() => {this.navigate("/adminsmods/" + element.slug);}}*/><div><span>{element.first_name + ' ' + element.last_name}</span></div></div></div>;})}
                <h1>{i18n.t('pages.team.mods')}</h1>
                {mods.map((element) => {return <div key={element.slug} className={"AdminsMods-head"}><div className={"rounded-circle " + element.slug + " border-" + element.house + " " + element.house} style={{backgroundImage: "url('/img/team/"+element.slug+".png')"}} /*onClick={() => {this.navigate("/adminsmods/" + element.slug);}}*/><div><span>{element.first_name + ' ' + element.last_name}</span></div></div></div>;})}
            </div>
        </Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(AdminsMods);

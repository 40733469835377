import React from 'react';
import { BaseComponent } from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import { Container } from 'reactstrap';
import admins from '../../config/admins.json';
import mods from '../../config/mods.json';
import {NoMatch} from "../index";
import "./AdminsModsDetail.css";

class AdminsModsDetail extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            am: undefined,
            type: ''
        }
    }

    componentWillMount() {
        for(let admin of admins) {
            if(admin.slug === this.props.match.params.name) {
                this.setState({am: admin, type: 'Admin'});
                this.props.changeTitle(admin.first_name + ' ' + admin.last_name);
            }
        }
        for(let mod of mods) {
            if(mod.slug === this.props.match.params.name) {
                this.setState({am: mod, type: 'Moderator'});
                this.props.changeTitle(mod.first_name + ' ' + mod.last_name);
            }
        }
    }

    render() {
        if(this.state.am) {
            let am = this.state.am;
            return <Container>
                {/*am.first_name*/} {/*am.last_name*/}
                <div className={'AdminModsDetail text-center'}>
                    <div key={am.slug} className={"AdminsMods-head"}>
                        <div className={"rounded-circle m-0 bighead mb-5 " + am.slug + " border-" + am.house + " " + am.house} style={{backgroundImage: "url('/img/team/"+am.slug+".png')"}}>
                            <div>
                                <span>{am.first_name + ' ' + am.last_name}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1>{am.first_name} {am.last_name}</h1>
                        <h3>{this.state.type}</h3>
                    </div>
                </div>
            </Container>;
        }
        else {
            return <NoMatch />
        }
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(AdminsModsDetail);

import React from 'react';
import {BaseComponent} from "../../common";
import i18n from "../../config/i18n";
import {changeTitle} from "../../redux/actions";
import {connect} from "react-redux";
import {Container} from "reactstrap";

class MyEvents extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.my-events'));
    }

    componentWillMount() {
        this.onLanguageChanged();
    }

    render() {
        return <Container>My events</Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(MyEvents);
import React from 'react';
import {BaseComponent} from '../../common';
import { changeTitle } from '../../redux/actions';
import {connect} from 'react-redux';
import { Container } from 'reactstrap';
import i18n from '../../config/i18n';

class Events extends BaseComponent {
    constructor(props) {
        super(props);
        this.onLanguageChanged = this.onLanguageChanged.bind(this);
    }

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.events'));
    }

    componentWillMount() {
        this.onLanguageChanged();
    }

    render() {
        return <Container>Voor onze evenementen verwijzen we je momenteel nog graag door naar onze <a target={'_BLANK'} href={'https://www.facebook.com/groups/belgianpotterheadsunite/'}>Facebook-groep!</a></Container>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(Events);
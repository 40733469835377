import { BaseComponent } from "../../common";
import { Button, Container, Input } from "reactstrap";
import i18n from "../../config/i18n";
import { connect } from "react-redux";
import { changeTitle } from "../../redux/actions";
import christmas from "./christmas.json";

class Christmas extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      started: false,
      paragraphs: [],
      textInput: "",
      inputWasTried: false,
    };
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.onStart = this.onStart.bind(this);
    this.goNext = this.goNext.bind(this);
    this.onSelectOption = this.onSelectOption.bind(this);
    this.reset = this.reset.bind(this);
    this.checkTextInput = this.checkTextInput.bind(this);
  }

  onStart() {
    this.setState({ started: true });
    this.goNext(0);
  }

  reset() {
    this.setState(
      {
        paragraphs: [],
        ended: false,
        won: false,
        currentElement: undefined,
        started: false,
      },
      this.onStart
    );
  }

  goNext(newPart) {
    const nextPart = newPart;
    this.setState((prevState) => {
      const prevParagraphs =
        christmas[prevState.currentElement]?.continue.type === "auto"
          ? prevState.paragraphs
          : prevState.paragraphs.map((p) => ({ text: p.text, new: false }));

      return {
        currentElement: nextPart,
        paragraphs: [
          ...prevParagraphs,
          ...christmas[nextPart].paragraphs.map((p) => ({
            text: p,
            new: true,
          })),
        ],
      };
    });

    if (christmas[nextPart].continue.type === "auto") {
      this.goNext(christmas[nextPart].continue.next);
    }

    if (christmas[nextPart].continue.type === "end") {
      this.setState({ ended: true, won: christmas[nextPart].continue.success });
    }
  }

  onSelectOption(optionIndex) {
    const option =
      christmas[this.state.currentElement].continue.options[optionIndex];

    this.goNext(option.next);
  }

  checkTextInput() {
    const value = this.state.textInput;
    const correctValues = christmas[this.state.currentElement].continue.answers;

    if (correctValues.filter((v) => value.toLowerCase().includes(v)).length) {
      this.setState({
        inputWasTried: false,
        textInput: "",
      });
      this.goNext(christmas[this.state.currentElement].continue.next);
    } else if (value.trim() !== "") {
      this.setState({
        inputWasTried: true,
      });
    }
  }

  onLanguageChanged() {
    this.props.changeTitle(i18n.t("title.christmas2023"));
  }
  componentWillMount() {
    this.onLanguageChanged();
  }
  render() {
    return (
      <Container>
        <h1>Kerstmis Interactief Verhaal</h1>
        {!this.state.started && (
          <>
            <p>
              Welkom, beste Belgian Potterhead! Ben je klaar om in een
              interactief verhaal rond Kerstmis te duiken? Druk snel op de knop
              om te starten!
            </p>
            <p className="text-center">
              <Button onClick={this.onStart}>Start je avontuur!</Button>
            </p>
          </>
        )}
        {this.state.started && (
          <>
            {this.state.paragraphs.map((p, i) => (
              <p
                key={i}
                dangerouslySetInnerHTML={{
                  __html: p.new ? `<b>${p.text}</b>` : p.text,
                }}
              />
            ))}
            {!this.state.ended && (
              <>
                {christmas[this.state.currentElement].continue.type ===
                  "options" && (
                  <>
                    <p>Wat doe je?</p>
                    {christmas[this.state.currentElement].continue.options?.map(
                      (option, i) => (
                        <div
                          className="text-center mb-3"
                          key={`${this.state.currentElement}-${i}`}
                        >
                          <Button
                            onClick={() => this.onSelectOption(i)}
                            className="text-break"
                          >
                            {option.optionText}
                          </Button>
                        </div>
                      )
                    )}
                  </>
                )}
                {christmas[this.state.currentElement].continue.type ===
                  "input" && (
                  <>
                    <p>Wat is volgens jou het antwoord?</p>
                    <Input
                      type="text"
                      value={this.state.textInput}
                      onChange={(e) =>
                        this.setState({ textInput: e.target.value })
                      }
                      onBlur={this.checkTextInput}
                    />
                    {this.state.inputWasTried && (
                      <>
                        <p>
                          <i>
                            {
                              christmas[this.state.currentElement].continue
                                .incorrectText
                            }
                          </i>
                        </p>
                        {christmas[this.state.currentElement].continue.hint && (
                          <p>
                            <strong>
                              Extra hint:{" "}
                              {
                                christmas[this.state.currentElement].continue
                                  .hint
                              }
                            </strong>
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {this.state.ended && (
          <>
            {this.state.won && (
              <div className="text-center">
                <h1>Proficiat! Je hebt succesvol het feestmaal gered!</h1>
                <p>
                  Namens iedereen bij Belgian Potterheads wensen we onze leden
                  een fijne en gezellige Kerst!
                </p>
                <Button onClick={this.reset}>Speel nog een keer!</Button>
              </div>
            )}
            {!this.state.won && (
              <div className="text-center">
                <h1>Helaas... Je hebt het feestmaal niet bereikt...</h1>
                <Button onClick={this.reset}>Probeer opnieuw</Button>
              </div>
            )}
          </>
        )}
      </Container>
    );
  }
}

export default connect(null, (dispatch) => {
  return { changeTitle: (title) => dispatch(changeTitle(title)) };
})(Christmas);

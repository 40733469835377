import React from 'react';
import {changeTitle} from "../../redux/actions";
import {connect} from "react-redux";
import {AdminComponent, BaseComponent} from "../../common";
import i18n from "../../config/i18n";
import {
    Button,
    Card, CardBody,
    Col, Container, Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText, Label,
    Row
} from "reactstrap";
import Toggle from 'react-toggle';
import deepcopy from 'deepcopy';

class AddEvent extends BaseComponent {
    constructor(props) {
        super(props);

        this.addArrayItem = this.addArrayItem.bind(this);
        this.changeArrayValue = this.changeArrayValue.bind(this);
        this.changeDefaultFieldBoolean = this.changeDefaultFieldBoolean.bind(this);
        this.changePersonCanBeRemoved = this.changePersonCanBeRemoved.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.createEvent = this.createEvent.bind(this);
        this.removeArrayItem = this.removeArrayItem.bind(this);

        this.onLanguageChanged = this.onLanguageChanged.bind(this);

        this.selectContacts = this.selectContacts.bind(this);

        let today = new Date();

        this.arrDefaults = {
            ticketTypes: {name: '', price: 0},
            dates: {date: "", startTime: "", endTime: "", endTimeEnabled: false, startTimeEnabled: true},
            extraFields: {name: '', type: 'text', choices: [''], minValueEnabled: true, maxValueEnabled: false, minValue: 0, maxValue: 0, dateType: '', minTimeEnabled: false, maxTimeEnabled: false, minTime: '', maxTime: ''},
            purchases: {name: '', price: 0, info: '', maxType: 'unlimited', maxNumber: 0}
        };

        this.state = {
            contacts: [],
            type: "",
            enableRegistrations: false,
            ticketTypes: [{...this.arrDefaults.ticketTypes}],
            dates: [{...this.arrDefaults.dates}],
            title: '',
            location: '',
            locationAddress: '',
            locationName: '',
            minRegistrations: 0,
            maxRegistrations: 0,
            minPersons: 0,
            maxPersons: 0,
            minPersonsPerRegistration: 0,
            maxPersonsPerRegistration: 0,
            defaultFields: {
                firstName: true,
                lastName: true,
                birthday: false,
                address: false,
                sex: false,
                email: false,
                phone: false
            },
            extraFields: [],
            startRegistrationsDate: today.getFullYear() + '-' + (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate(),
            endRegistrationsDate: '',
            dateArrangement: 'multipleDates',
            personCanBeRemoved: false,
            multipleDateType: 'apart',
            facebook: '',
            visibleDate: today.getFullYear() + '-' + (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate(),
            visibleTime: '',
            visibleTimeEnabled: false,
            subevent: "",
            purchases: [],
            purchasesType: 'with_registration'
        }
    }

    changeDefaultFieldBoolean = (prop) => (e) => {
        this.setState((prev) => {
            let df = {...prev.defaultFields};
            df[prop] = !df[prop];
            return {defaultFields: df};
        });
    };

    changePersonCanBeRemoved(e) {
        this.setState({personCanBeRemoved: !this.state.personCanBeRemoved});
    }

    changeValue = (prop, type = '') => (e) => {
        let o = {};
        let value = e.target.value;
        switch(type) {
            case 'int':
                value = !isNaN(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : (e.target.value === '' ? '' : 0);
                break;
            case 'float':
                value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : (e.target.value === '' ? '' : 0);
                break;
            default:
                break;
        }
        o[prop] = value;
        this.setState(o);
    };

    addArrayItem = (array) => (e) => {
        this.setState((state) => {
            let o = {};
            let m = {...this.arrDefaults[array]};
            if(array === 'extraFields')
                m.choices = [''];
            o[array] = [...state[array], m];
            return o;
        });
    };

    removeArrayItem = (array, id) => (e) => {
        this.setState((state) => {
            let values = [...state[array]];
            values.splice(id, 1);
            let o = {};
            o[array] = values;
            return o;
        });
    };

    changeArrayValue = (array, id, prop, type = '') => (e) => {
        e.persist();
        this.setState((prev) => {
            //console.log(prev[array]);
            let values = [...prev[array]];
            let value = e.target.value;
            switch(type) {
                case 'int':
                    value = !isNaN(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : (e.target.value === '' ? '' : 0);
                    break;
                case 'float':
                    value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : (e.target.value === '' ? '' : 0);
                    break;
                default:
                    break;
            }
            values[id][prop] = value;
            let o = {};
            o[array] = values;
            return o;
        });
    };

    addChoiceOption = (i) => (e) => {
        this.setState((prev) => {
            let fields = [...prev.extraFields];
            fields[i].choices.push('');
            return {extraFields: fields};
        });
    };

    changeChoiceOption = (i, j) => (e) => {
        e.persist();
        this.setState((prev) => {
            let fields = [...prev.extraFields];
            fields[i].choices[j] = e.target.value;
            return {extraFields: fields};
        });
    };

    removeChoiceOption = (i) => (e) => {
        this.setState((prev) => {
            let fields = [...prev.extraFields];
            fields[i].choices.splice(i, 1);
            return {extraFields: fields};
        });
    };

    onLanguageChanged() {
        this.props.changeTitle(i18n.t('title.addEvent'));
    }
    componentWillMount() {
        this.onLanguageChanged();
    }

    selectContacts(e) {
        let options = e.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        //console.log(value);
        this.setState({contacts: value});
    }

    getFormTextPurchasesMaxType(i) {
        switch(this.state.purchases[i].maxType) {
            case 'per_registration':
                return 'Aantal van deze aankoop dat kan besteld worden per inschrijving.';
            case 'per_person':
                return 'Aantal van deze aankoop dat kan besteld worden per persoon.';
            case 'max_value':
                return 'Maximum aantal van deze aankoop dat per lid / inschrijving kan worden besteld.';
            default:
                return '';
        }
    }

    checkPurchasesForRegistrationNeeded() {
        for(let i = 0; i < this.state.purchases.length; i++) {
            if(this.state.purchases[i].maxType === 'per_registration' || this.state.purchases[i].maxType === 'per_person') return false;
        }
        return true;
    }

    createEvent() {
        console.log('Creating event...');
        let event = deepcopy(this.state);

        // Remove unnecessary values
        if(!event.enableRegistrations) {
            delete event.ticketTypes;
            delete event.minRegistrations;
            delete event.maxRegistrations;
            delete event.minPersons;
            delete event.maxPersons;
            delete event.minPersonsPerRegistration;
            delete event.maxPersonsPerRegistration;
            delete event.defaultFields;
            delete event.extraFields;
            delete event.startRegistrationsDate;
            delete event.endRegistrationsDate;
            delete event.personCanBeRemoved;
            delete event.dateArrangement;
        }
        if(event.multipleDateType === 'together')
            delete event.dateArrangement;

        if(!this.checkPurchasesForRegistrationNeeded())
            event.purchasesType = 'with_registration';

        console.log(event);
    }

    render() {
        return <AdminComponent onTitleChange={this.onLanguageChanged}>
            <Container>
                <Form>
                    <h2>Algemene informatie</h2>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="eventName">Titel</Label>
                                        <Input type="text" name="eventName" id="eventName" value={this.state.title} onChange={this.changeValue('title')} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="eventLocation">Locatie</Label>
                                        <Input type="select" name="eventLocation" id="eventLocation" value={this.state.location} onChange={this.changeValue('location')}>
                                            <option value={""}>-- Nieuwe locatie toevoegen --</option>
                                            <option value={"testiddatgeenechteidis"}>Testlocatie</option>
                                        </Input>
                                    </FormGroup>
                                    {this.state.location === '' && <div>
                                        <FormGroup>
                                            <Label for="eventLocationName">Locatie benaming</Label>
                                            <Input type="text" name="eventLocationName" id="eventLocationName" value={this.state.locationName} onChange={this.changeValue('locationName')} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="eventLocationAddress">Locatie adres</Label>
                                            <Input type="text" name="eventLocationAddress" id="eventLocationAddress" value={this.state.locationAddress} onChange={this.changeValue('locationAddress')} />
                                        </FormGroup>
                                    </div>}
                                    <FormGroup>
                                        <Label for="eventType">Type</Label>
                                        <Input type="select" name="eventType" id="eventType" value={this.state.type} onChange={this.changeValue('type')}>
                                            <option value={""} disabled>Selecteer een type</option>
                                            <option value={'convention'}>Conventie</option>
                                            <option value={'event'}>Evenement</option>
                                            <option value={'meet'}>Meet</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                                        <Label for="eventContacts">Contactpersoon</Label>
                                        <Input type="select" value={this.state.contacts} name="eventContacts" id="eventContacts" multiple style={{height: 200, flex: 1}} onChange={this.selectContacts}>
                                            <optgroup label="Admins">
                                                <option value={"angela"}>Angela Evrard</option>
                                                <option value={"evy"}>Evy Van Damme</option>
                                                <option value={"joris"}>Joris De Buck</option>
                                                <option value={"lyna"}>Lyna Deweys</option>
                                                <option value={"matthias"}>Matthias Anthonis</option>
                                                <option value={"5ae1844fdb3722b7f830d556"}>Olivier Van Bulck</option>
                                                <option value={"yolan"}>Yolan Vandekerkhof</option>
                                            </optgroup>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="eventVisibleDate">Evenement zichtbaar vanaf</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="far fa-calendar-alt"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="date" name="eventVisibleDate" id="eventVisibleDate" value={this.state.visibleDate} onChange={this.changeValue('visibleDate')} />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for={"eventVisibleTime"}>&nbsp;</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="far fa-clock"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <Toggle
                                                        defaultChecked={this.state.visibleTimeEnabled}
                                                        onChange={(e) => {this.setState((prev) => {
                                                            return {visibleTimeEnabled: !prev.visibleTimeEnabled};
                                                        })}} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="time" name="eventVisibleTime" id="eventVisibleTime" value={this.state.visibleTime} onChange={this.changeValue('visibleTime')} disabled={!this.state.visibleTimeEnabled} />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="eventSubevent">Subevenement van...</Label>
                                        <Input type="select" name="eventSubevent" id="eventSubevent" value={this.state.subevent} onChange={this.changeValue('subevent')}>
                                            <option value={""}>-- Geen subevenement --</option>
                                            <option value={"test"}>Testevenement</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <h2>Inschrijvingen</h2>
                    <label>
                        <Toggle
                            defaultChecked={this.state.enableRegistrations}
                            onChange={() => {this.setState({enableRegistrations: !this.state.enableRegistrations})}} />
                    </label>
                    {this.state.enableRegistrations && <div>
                        {/* All about registrations */}
                        <Card className="well-info mb-5 mt-3">
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventMinPersons"}>Minimum personen</Label>
                                            <Input type="number" name={"eventMinPersons"} id={"eventMinPersons"} value={this.state.minPersons} onChange={this.changeValue('minPersons', 'int')} min={0} />
                                            <FormText>Het minimum aantal <strong>personen</strong> om het evenement te laten doorgaan. Bij 0 wordt dit genegeerd.</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventMaxPersons"}>Maximum personen</Label>
                                            <Input type="number" name={"eventMaxPersons"} id={"eventMaxPersons"} value={this.state.maxPersons} onChange={this.changeValue('maxPersons', 'int')} min={0} />
                                            <FormText>Het maximum aantal <strong>personen</strong> dat mogelijk zijn. Bij 0 wordt dit genegeerd.</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventMinTickets"}>Minimum inschrijvingen</Label>
                                            <Input type="number" name={"eventMinTickets"} id={"eventMinTickets"} value={this.state.minRegistrations} onChange={this.changeValue('minRegistrations', 'int')} min={0} />
                                            <FormText>Het minimum aantal <strong>inschrijvingen</strong> om het evenement te laten doorgaan. Bij 0 wordt dit genegeerd.</FormText>
                                            <FormText>Handig voor evenementen waar ze in teams moeten inschrijven.</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventMaxTickets"}>Maximum inschrijvingen</Label>
                                            <Input type="number" name={"eventMaxTickets"} id={"eventMaxTickets"} value={this.state.maxRegistrations} onChange={this.changeValue('maxRegistrations', 'int')} min={0} />
                                            <FormText>Het maximum aantal <strong>inschrijvingen</strong> dat mogelijk zijn. Bij 0 wordt dit genegeerd.</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventMinPersonsPerRegistration"}>Minimum personen per inschrijving</Label>
                                            <Input type="number" name={"eventMinPersonsPerRegistration"} id={"eventMinPersonsPerRegistration"} value={this.state.minPersonsPerRegistration} onChange={this.changeValue('minPersonsPerRegistration', 'int')} min={0} />
                                            <FormText>Het minimum aantal <strong>personen</strong> dat nodig is per inschrijving. Bij 0 wordt dit genegeerd.</FormText>
                                            <FormText>Handig voor evenementen waar ze in teams moeten inschrijven.</FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventMaxPersonsPerRegistration"}>Maximum personen per inschrijving</Label>
                                            <Input type="number" name={"eventMaxPersonsPerRegistration"} id={"eventMaxPersonsPerRegistration"} value={this.state.maxPersonsPerRegistration} onChange={this.changeValue('maxPersonsPerRegistration', 'int')} min={0} />
                                            <FormText>Het maximum aantal <strong>personen</strong> dat mogelijk is per inschrijving. Bij 0 wordt dit genegeerd.</FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventStartRegistrationsDate"}>Startdatum inschrijvingen</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="far fa-calendar-alt"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="date" name={"eventStartRegistrationsDate"} id={"eventStartRegistrationsDate"} value={this.state.startRegistrationsDate} onChange={this.changeValue('startRegistrationsDate')} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for={"eventEndRegistrationsDate"}>Einddatum inschrijvingen</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="far fa-calendar-alt"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="date" name={"eventEndRegistrationsDate"} id={"eventEndRegistrationsDate"} value={this.state.endRegistrationsDate} onChange={this.changeValue('endRegistrationsDate')} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" checked={this.state.personCanBeRemoved} onChange={this.changePersonCanBeRemoved} />{' '}
                                                Persoon kan zichzelf verwijderen en enkel anderen inschrijven
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h6>Ticket types</h6>
                        <Card className="well-info mb-5">
                            <CardBody>
                                {this.state.ticketTypes.map((el, i) => {
                                    return <div key={i}>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for={"eventTicketTypes."+i+".name"}>Naam</Label>
                                                    <Input type="text" name={"eventTicketTypes."+i+".name"} id={"eventTicketTypes."+i+".name"} onChange={this.changeArrayValue('ticketTypes', i, 'name')} value={this.state.ticketTypes[i].name} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for={"eventTicketTypes."+i+".price"}>Prijs</Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="fas fa-euro-sign"/>
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input type="number" name={"eventTicketTypes."+i+".price"} id={"eventTicketTypes."+i+".price"} onChange={this.changeArrayValue('ticketTypes', i, 'price', 'float')} value={this.state.ticketTypes[i].price} min={0} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            {this.state.ticketTypes.length !== 1 && <Col md={'auto'}>
                                                <Button color={'danger'} style={{position: 'relative', bottom: -32}} onClick={this.removeArrayItem('ticketTypes', i)}><i className="fas fa-times"/></Button>
                                            </Col>}
                                        </Row>
                                        <hr />
                                    </div>;
                                })}
                                <Button color={'primary'} onClick={this.addArrayItem('ticketTypes')}><i className="fas fa-plus"/> Tickettype toevoegen</Button>
                            </CardBody>
                        </Card>
                        <h6>Standaard velden</h6>
                        <Card className="well-info mb-5">
                            <CardBody>
                                <FormText>Duid aan welke gegevens per persoon gevraagd dienen te worden.</FormText>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" disabled={true} checked={this.state.defaultFields.firstName} />{' '}
                                        Voornaam
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" disabled={true} checked={this.state.defaultFields.lastName} />{' '}
                                        Familienaam
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked={this.state.defaultFields.birthday} onChange={this.changeDefaultFieldBoolean('birthday')} />{' '}
                                        Geboortedatum
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked={this.state.defaultFields.address} onChange={this.changeDefaultFieldBoolean('address')} />{' '}
                                        Adres
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked={this.state.defaultFields.sex} onChange={this.changeDefaultFieldBoolean('sex')} />{' '}
                                        Geslacht
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked={this.state.defaultFields.email} onChange={this.changeDefaultFieldBoolean('email')} />{' '}
                                        E-mail
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked={this.state.defaultFields.phone} onChange={this.changeDefaultFieldBoolean('phone')} />{' '}
                                        Telefoon
                                    </Label>
                                </FormGroup>
                            </CardBody>
                        </Card>
                        <h6>Extra velden</h6>
                        <Card className="well-info mb-5">
                            <CardBody>
                                {this.state.extraFields.map((el, i) => {
                                    return <div key={i}>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for={"eventExtraFields."+i+".name"}>Naam</Label>
                                                    <Input type="text" name={"eventExtraFields."+i+".name"} id={"eventExtraFields."+i+".name"} onChange={this.changeArrayValue('extraFields', i, 'name')} value={this.state.extraFields[i].name} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for={"eventExtraFields."+i+".type"}>Type</Label>
                                                    <Input type="select" value={this.state.extraFields[i].type} name={"eventExtraFields."+i+".type"} id={"eventExtraFields."+i+".type"} onChange={this.changeArrayValue('extraFields', i, 'type')}>
                                                        <option value={"text"}>Tekst</option>
                                                        <option value={"textarea"}>Lange tekst</option>
                                                        <option value={"integer"}>Geheel getal</option>
                                                        <option value={"decimal"}>Decimaal getal</option>
                                                        <option value={"boolean"}>Ja / Nee</option>
                                                        <option value={"date"}>Datum</option>
                                                        <option value={"time"}>Tijd</option>
                                                        <option value={"datetime"}>Datum en tijd</option>
                                                        <option value={"choice"}>Keuze</option>
                                                        <option value={"multipleChoice"}>Meerkeuze</option>
                                                        {/*<option value={"file"}>Bestand</option>*/}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={'auto'}>
                                                <Button color={'danger'} style={{position: 'relative', bottom: -32}} onClick={this.removeArrayItem('extraFields', i)}><i className="fas fa-times"/></Button>
                                            </Col>
                                        </Row>
                                        {(this.state.extraFields[i].type === 'choice' || this.state.extraFields[i].type === 'multipleChoice') && <div>
                                            {this.state.extraFields[i].choices.map((el, j) => {
                                                return <Row key={j}>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for={"eventExtraFields."+i+".choices."+j}>Optie #{j + 1}</Label>
                                                            <Input type="text" name={"eventExtraFields."+i+".choices."+j} id={"eventExtraFields."+i+".choices."+j} value={this.state.extraFields[i].choices[j]} onChange={this.changeChoiceOption(i, j)} />
                                                        </FormGroup>
                                                        <hr />
                                                    </Col>
                                                    {this.state.extraFields[i].choices.length > 1 && <Col md={'auto'}>
                                                        <Button color={'secondary'} style={{position: 'relative', bottom: -32}} onClick={this.removeChoiceOption(i)}><i className="fas fa-times"/></Button>
                                                    </Col>}
                                                </Row>;
                                            })}
                                            <Button color={'success'} size={'sm'} onClick={this.addChoiceOption(i)}><i className="fas fa-plus"/> Optie toevoegen</Button>
                                        </div>}
                                            {(this.state.extraFields[i].type === 'integer' || this.state.extraFields[i].type === 'decimal') && <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for={"eventExtraFields."+i+".minValue"}>Minimum</Label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <Toggle
                                                                        defaultChecked={this.state.extraFields[i].minValueEnabled}
                                                                        onChange={(e) => {this.setState((prev) => {
                                                                            let fields = [...prev.extraFields];
                                                                            fields[i].minValueEnabled = !fields[i].minValueEnabled;
                                                                            return {extraFields: fields};
                                                                        })}} />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="number" name={"eventExtraFields."+i+".minValue"} id={"eventExtraFields."+i+".minValue"} disabled={!this.state.extraFields[i].minValueEnabled} value={this.state.extraFields[i].minValue} onChange={this.changeArrayValue('extraFields', i, 'minValue', (this.state.extraFields[i].type === 'integer' ? 'int' : 'float'))} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for={"eventExtraFields."+i+".maxValue"}>Minimum</Label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <Toggle
                                                                        defaultChecked={this.state.extraFields[i].maxValueEnabled}
                                                                        onChange={(e) => {this.setState((prev) => {
                                                                            let fields = [...prev.extraFields];
                                                                            fields[i].maxValueEnabled = !fields[i].maxValueEnabled;
                                                                            return {extraFields: fields};
                                                                        })}} />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="number" name={"eventExtraFields."+i+".maxValue"} id={"eventExtraFields."+i+".maxValue"} disabled={!this.state.extraFields[i].maxValueEnabled} value={this.state.extraFields[i].maxValue} onChange={this.changeArrayValue('extraFields', i, 'maxValue', (this.state.extraFields[i].type === 'integer' ? 'int' : 'float'))} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                            {(this.state.extraFields[i].type === 'date' || this.state.extraFields[i].type === 'datetime') &&
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for={"eventExtraFields."+i+".type"}>Toegelaten datums</Label>
                                                        <Input type="select" value={this.state.extraFields[i].dateType} name={"eventExtraFields."+i+".dateType"} id={"eventExtraFields."+i+".dateType"} onChange={this.changeArrayValue('extraFields', i, 'dateType')}>
                                                            <option value={""}>Alle datums mogelijk</option>
                                                            <option value={"beforeToday"}>Voor huidige dag</option>
                                                            <option value={"afterToday"}>Na huidige dag</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                            {(this.state.extraFields[i].type === 'time' || this.state.extraFields[i].type === 'datetime') &&
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for={"eventExtraFields." + i + ".minTime"}>Minimum tijd</Label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="far fa-clock"/>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <Toggle
                                                                        defaultChecked={this.state.extraFields[i].minTimeEnabled}
                                                                        onChange={(e) => {this.setState((prev) => {
                                                                            let fields = [...prev.extraFields];
                                                                            fields[i].minTimeEnabled = !prev.extraFields[i].minTimeEnabled;
                                                                            return {extraFields: fields};
                                                                        })}} />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="time" name={"eventExtraFields." + i + ".minTime"} id={"eventExtraFields." + i + ".minTime"} value={this.state.extraFields[i].minTime} onChange={this.changeArrayValue('extraFields', i, 'minTime')} disabled={!this.state.extraFields[i].minTimeEnabled} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for={"eventExtraFields." + i + ".maxTime"}>Maximum tijd</Label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="far fa-clock"/>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <Toggle
                                                                        defaultChecked={this.state.extraFields[i].maxTimeEnabled}
                                                                        onChange={(e) => {this.setState((prev) => {
                                                                            let fields = [...prev.extraFields];
                                                                            fields[i].maxTimeEnabled = !prev.extraFields[i].maxTimeEnabled;
                                                                            return {extraFields: fields};
                                                                        })}} />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="time" name={"eventExtraFields." + i + ".maxTime"} id={"eventExtraFields." + i + ".maxTime"} value={this.state.extraFields[i].maxTime} onChange={this.changeArrayValue('extraFields', i, 'maxTime')} disabled={!this.state.extraFields[i].maxTimeEnabled} />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                    <hr />
                                    </div>
                                })}
                                <Button color={'primary'} onClick={this.addArrayItem('extraFields')}><i className="fas fa-plus"/> Veld toevoegen</Button>
                            </CardBody>
                        </Card>
                    </div>}
                    <h2>Extra aankopen</h2>
                    <span className={'text-muted small'}>Zaken die op voorhand aangekocht kunnen worden voor het evenement. Wanneer er inschrijvingen zijn kunnen die mee bij een inschrijving worden besteld, anders apart.</span><br /><br />
                    <Card className="well-info mb-5">
                        <CardBody>
                            {this.state.purchases.map((el, i) => {
                                return <div key={i}>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for={"eventPurchases."+i+".name"}>Naam</Label>
                                                <Input type="text" name={"eventPurchases."+i+".name"} id={"eventPurchases."+i+".name"} onChange={this.changeArrayValue('purchases', i, 'name')} value={this.state.purchases[i].name} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for={"eventPurchases."+i+".price"}>Prijs</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fas fa-euro-sign"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="number" name={"eventPurchases."+i+".price"} id={"eventPurchases."+i+".price"} onChange={this.changeArrayValue('purchases', i, 'price', 'float')} value={this.state.purchases[i].price} min={0} />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={'auto'}>
                                            <Button color={'danger'} style={{position: 'relative', bottom: -32}} onClick={this.removeArrayItem('purchases', i)}><i className="fas fa-times"/></Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for={"eventPurchases."+i+"info"}>Info</Label>
                                                <Input type="textarea" name="text" id={"eventPurchases."+i+"info"} onChange={this.changeArrayValue('purchases', i, 'info')} value={this.state.purchases[i].info} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for={"eventPurchases."+i+".maxType"}>Type maximum</Label>
                                                <Input type="select" value={this.state.purchases[i].maxType} name={"eventPurchases."+i+".maxType"} id={"eventPurchases."+i+".maxType"} onChange={this.changeArrayValue('purchases', i, 'maxType')}>
                                                    <option value={"unlimited"}>Ongelimiteerd</option>
                                                    {this.state.enableRegistrations && <option value={"per_registration"}>Max. per inschrijving</option>}
                                                    {this.state.enableRegistrations && <option value={"per_person"}>Max. per persoon</option>}
                                                    <option value={"max_value"}>Maximum waarde</option>

                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        {this.state.purchases[i].maxType !== 'unlimited' && <Col>
                                            <FormGroup>
                                                <Label for={'eventPurchases.'+i+'.maxNumber'}>Waarde</Label>
                                                <Input type="number" name={'eventPurchases.'+i+'.maxNumber'} id={'eventPurchases.'+i+'.maxNumber'} value={this.state.purchases[i].maxNumber} onChange={this.changeArrayValue('purchases', i, 'maxNumber', 'int')} min={0} />
                                                <FormText>
                                                    {this.getFormTextPurchasesMaxType(i)}
                                                </FormText>
                                            </FormGroup>
                                        </Col>}
                                    </Row>
                                    <hr />
                                </div>;
                            })}
                            {this.state.enableRegistrations && this.state.purchases.length > 0 && <div><FormGroup>
                                <Label for={"eventPurchasesType"}>Type aankopen</Label>
                                <Input type="select" value={this.state.purchasesType} name={"eventPurchasesType"} id={"eventPurchasesType"} onChange={this.changeValue('purchasesType')} disabled={!this.checkPurchasesForRegistrationNeeded()}>
                                    <option value={"with_registration"}>Aankopen gebeuren samen met inschrijving</option>
                                    <option value={"without_registration"}>Aankopen gebeuren apart van inschrijving</option>
                                    <option value={"both"}>Beide mogelijk</option>
                                </Input>
                                {!this.checkPurchasesForRegistrationNeeded() && <FormText color={'danger'}>
                                    Deze instelling kan niet worden aangepast zolang minstens één van de aankopen een registratie nodig heeft (per inschrijving of per persoon). Zolang dit waar is, zal er standaard gekozen worden om de aankopen per inschrijving te laten voltooien.
                                </FormText>}
                            </FormGroup><hr/></div>}
                            <Button color={'primary'} onClick={this.addArrayItem('purchases')}><i className="fas fa-plus"/> Aankoop toevoegen</Button>
                        </CardBody>
                    </Card>
                    <h2>Datums</h2>
                    <Card className="well-info mb-5">
                        <CardBody>
                            {this.state.dates.map((el, i) => {
                                return <div key={i}>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for={"eventDate."+i+".date"}>Datum</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="far fa-calendar-alt"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="date" name={"eventDate."+i+".date"} id={"eventDate."+i+".date"} value={this.state.dates[i].date} onChange={this.changeArrayValue('dates', i, 'date')} />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        {this.state.dates.length !== 1 && <Col sm={12} md={'auto'}>
                                            <Button color={'danger'} style={{position: 'relative', bottom: -32}} onClick={this.removeArrayItem('dates', i)}><i className="fas fa-times"/></Button>
                                        </Col>}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for={"eventDate."+i+".startTime"}>Starttijd</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="far fa-clock"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <Toggle
                                                                defaultChecked={this.state.dates[i].startTimeEnabled}
                                                                onChange={(e) => {this.setState((prev) => {
                                                                    let dates = [...prev.dates];
                                                                    dates[i].startTimeEnabled = !dates[i].startTimeEnabled;
                                                                    return {dates};
                                                                })}} />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="time" name={"eventDate."+i+".startTime"} id={"eventDate."+i+".startTime"} disabled={!this.state.dates[i].startTimeEnabled} value={this.state.dates[i].startTime} onChange={this.changeArrayValue('dates', i, 'startTime')} />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for={"eventDate."+i+".endTime"}>Eindtijd</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="far fa-clock"/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <Toggle
                                                                checked={this.state.dates[i].startTimeEnabled ? this.state.dates[i].endTimeEnabled : false}
                                                                onChange={(e) => {this.setState((prev) => {
                                                                    let dates = [...prev.dates];
                                                                    dates[i].endTimeEnabled = !dates[i].endTimeEnabled;
                                                                    return {dates};
                                                                })}} />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="time" name={"eventDate."+i+".endTime"} id={"eventDate."+i+".endTime"} disabled={!this.state.dates[i].startTimeEnabled || !this.state.dates[i].endTimeEnabled} value={this.state.dates[i].endTime} onChange={this.changeArrayValue('dates', i, 'endTime')} />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>;
                            })}
                            {this.state.dates.length > 1 && <div>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="eventMultipleDateType" value={'apart'} checked={this.state.multipleDateType === 'apart'} onChange={this.changeValue('multipleDateType')} />{' '}
                                        Het evenement wordt op meerdere dagen herhaald
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="eventMultipleDateType" value={'together'} checked={this.state.multipleDateType === 'together'} onChange={this.changeValue('multipleDateType')} />{' '}
                                        Alle datums behoren tot één evenement
                                    </Label>
                                </FormGroup>
                                <hr />
                            </div>}
                            {this.state.dates.length > 1 && this.state.enableRegistrations && this.state.multipleDateType === 'apart' && <div>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="eventMultipleDateRegulation" value={'oneDate'} checked={this.state.dateArrangement === 'oneDate'} onChange={this.changeValue('dateArrangement')} />{' '}
                                        Elke persoon kan op één datum inschrijven
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="eventMultipleDateRegulation" value={'multipleDates'} checked={this.state.dateArrangement === 'multipleDates'} onChange={this.changeValue('dateArrangement')} />{' '}
                                        Elke persoon kan zich op meerdere dagen inschrijven
                                    </Label>
                                </FormGroup>
                                <hr />
                            </div>}
                            <Button color={'primary'} onClick={this.addArrayItem('dates')}><i className="fas fa-plus"/> Datum toevoegen</Button>
                        </CardBody>
                    </Card>
                    <h2>Sociale media</h2>
                    <Card className="well-info mb-5">
                        <CardBody>
                            <FormGroup>
                                <Label for="eventFacebook">Facebook</Label>
                                <Input type="url" name="eventFacebook" id="eventFacebook" value={this.state.facebook} onChange={this.changeValue('facebook')} />
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <Button color={'info'} size={'lg'} onClick={this.createEvent}>Evenement aanmaken</Button>
                </Form>
            </Container>
        </AdminComponent>;
    }
}

export default connect(null, (dispatch) => {return {changeTitle: title => dispatch(changeTitle(title))}})(AddEvent);
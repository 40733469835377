import React from 'react';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {Button} from "reactstrap";
import {BaseComponent} from "../index";

export default class FacebookLoginButton extends BaseComponent {
    constructor(props) {
        super(props);
        this.handleResponse = this.handleResponse.bind(this);
    }

    handleResponse(response) {
        fetch('http://local.belgianpotterheads.be:8000/auth/login/facebook', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(response)
        }).then((response) => {
            return response.json();
        }).then((response) => {
            console.log(response);
        });
    }

    render() {
        return <FacebookLogin
            appId="754128268092411"
            autoLoad={false}
            fields="name,first_name,last_name,email,picture,gender,locale,timezone,link"
            scope="public_profile,email"
            callback={this.handleResponse}
            render={renderProps => {
                return <Button color={'primary'} className={'facebook-login-button'} onClick={renderProps.onClick}><i className={'fab fa-facebook'} /> Inloggen via Facebook</Button>;
            }}
        />;
    }
}